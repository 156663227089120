import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import CartSummary from '../Components/CartSummary'
import TotalSummary from '../Components/TotalSummary'
import { useEffect } from 'react'
import { setCurrentPage } from '../actions'


const Payment = () => {
  const [redirectCountdown, setRedirectCountdown] = useState(5);
  const navigate = useNavigate()
  const cart = useSelector((state) => state.app.cart)
  const redirectUrl = useSelector((state) => state.app.paymentUrl)
  const queryParams = useSelector((state) => state.app.queryParams)
  const dispatch = useDispatch();

  useEffect(() => {
    // Start the countdown timer
    const timer = setInterval(() => {
      setRedirectCountdown((prevCountdown) => prevCountdown - 1);
    }, 1000); // Countdown interval set to 1 second

    // Redirect to payment page after countdown reaches 0
    if (redirectCountdown === 0) {
      clearInterval(timer); // Clear the timer
      navigate(`?${queryParams}`)
      window.open(redirectUrl,"_self");
      console.log(redirectUrl);
      dispatch(setCurrentPage(3))
      // Redirect to payment page
    }

    // Cleanup function to clear the interval when component unmounts
    return () => clearInterval(timer);
  }, [redirectCountdown]);


  const cartArray = Object.values(cart)

  const calculateSubtotal = () => {
    // Calculate the subtotal using the reduce method on the array of products
    return cartArray.reduce((acc, product) => {
      const productTotal = product.product_price * product.quantity
      // Add the product total to the accumulator
      return acc + productTotal
    }, 0)

  }

  const calculateServiceFees = () => {
    const subtotal = calculateSubtotal()
    return subtotal * 0.05 // Assuming service charge is 5%
  }

  const calculateDiscount = () => {
    const subtotal = calculateSubtotal()
    return subtotal * 0.1 // Assuming discount is 10%
  }

  const calculateTotal = () => {
    const subtotal = calculateSubtotal()
    const serviceFees = calculateServiceFees()
    const discount = calculateDiscount()
    return (subtotal + serviceFees - discount)
  }

  const total = calculateTotal()

  const summaryItems = [
    { label: 'Subtotal', value: calculateSubtotal() },
    { label: 'Service fees (5%)', value: calculateServiceFees() },
    { label: 'Discount (10%)', value: calculateDiscount() },
    { label: 'Total', value: total.toFixed(2) },
  ]

  return (
    <div className="flex flex-col items-center justify-center px-4 text-lg">
      <CartSummary cart={cartArray} summaryItems={summaryItems} />
      <TotalSummary cart={cart} total={total} />
      {/* <PaymentMethods /> */}
      <div className="mt-4 text-center">
        Redirecting to Payment in <b className=' font-extrabold text-xl '>{redirectCountdown}</b>s...
      </div>
    </div>
  )
}

export default Payment
